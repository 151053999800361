import React from 'react';

import * as Styled from './styles';



const GoToTop: React.FC = (props) => {
  const testfun = () =>{   
    window.scrollTo({top: 0, behavior: 'smooth'})
  }
  return(
  <Styled.GoToTop>
    <button className="GoToTop" onClick={testfun} >Go to top ^</button>
  </Styled.GoToTop>
);
}

export default GoToTop;
