import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import GoToTop from '../components/ui/GoToTop'

import pic1 from '../assets/images/prosthesis_parts.jpg'
import pic1a from '../assets/images/machining_and_fitting.jpg'
import pic2 from '../assets/images/ambulation_modes.jpg'
import pic3 from '../assets/images/stairs_osl.jpg'
import pic4 from '../assets/images/tmr.jpg'



const Prosthetics = (props) => (
    <Layout>
        <Helmet>
            <title>Prosthetics - Jonathan Camargo</title>
            <meta name="description" content="Prosthetics" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Prosthetics</h1>
                    </header>
                    <p>Robotic systems are a potential solution for improving the quality of life for individuals with amputation. 
                     Some key aspects of these technologies are: the capability to provide positive work and the use of localized sensors to obtain information of the system. 
                     However, these systems are complex mechanically, electrically and in software. They include transmisions, actuation systems, and an embedded control that responds to the user actions in a dynamic environment.
                    </p>    
                    <span className="image main"><img src={pic1} alt="" /></span>                                
                </div>        
            </section>    

            <section id="two">

                <div className="inner">                   
                    <p>
                     From the experience initiating the prosthetics work at EPIC lab, we have framed better strategies to manage that system complexity from machining to experimentation. We can create solutions that are robust for research and also accomodate to the user's needs.</p>
                     <span className="image main"><img src={pic1a} alt="" /></span>                  
                </div>               

                <div className="inner">                   
                    <p>
                     To enable walking with robotic prostheses, a holistic view of the locomotion is necessary, understanding ambulation in different terrain contexts. 
                     Users will require the prosthesis to adjust to changes in gait, driven by day-to-day community ambulation, including different stairs and ramps.
                     At EPIC lab I designed impedance control software to provide smooth interaction with the prosthesis. With our prosthetics team, we built robotic legs that are able to respond to different locomotion modes, poviding the users 
                     with better gait patterns than traditional passive solutions. In our article published in the <a href="https://academic.oup.com/milmed/article/185/Supplement_1/490/5670554?login=true">Journal of Military Medicine</a> we demonstrated the control strategies in multiple
                     ambulation modes.
                     </p>                   
                     <span className="image main"><img src={pic2} alt="" /></span>                  
                </div>                

                <div className="inner">                        
                    <p>
                    Beyond the implementation of robotic prostheses, my research combines the careful study of the locomotion of healthy and indviduals with amputation to provide major improvements in the locomotion with powered prostheses.
                    I proposed improved strategies for the prosthetic control. My latest submission, under review in the <a href="http://www.ieee-tmrb.org/">Transactions on Medical Robotics and Bionics</a>, offers an impedance modulation for 
                    improving the controler towards the biological gait.
                    </p>               
                    <span className="image main"><img src={pic3} alt="" /></span>                  
                                         
                    <p>
                    In addition, I implemented opimization methods so that a leg can self-discover the impedance parameters that match a particular locomotion pattern.
                    In the <a href="https://asmedigitalcollection.asme.org/DSCC/proceedings-abstract/DSCC2018/51913/V003T29A006/271072"> ASME Dynamic Systems and Control Conference</a>, I presented a stochastic search
                    of impedance parameters for the swing phase of level walking. In the future I would like to explore reinforcement learning techniques to identify optimal impedance controllers for walking in multiple ambulation modes.                
                    </p>






                </div>                
            </section>
                
            <section id="two">                
                <div className="inner">                        
                    <p>
                    My dissertation focused on lower limb, but I have a particular interest in upper limb prosthetics since my undergraduate years in Uniandes. With Dr. Young at the EPIC lab, I had the chance to work with a novel solution for neural signal control from EMG: 
                    targeted muscle reinnervation. This is a surgical method to attach residual nerves from the amputation to new muscle targets. With this method we can improve the prosthesis control by
                    having a more rich set of signals. In my article published in the <a href="https://ieeexplore.ieee.org/document/8663349">Transactions on Neural Systems and Rehabilitation Engineering</a> I studied the features obtained from
                    these type of signals and implemented algorithms that classify more than 20 different motions, simultaneously driving the wrist, elbow and hand opening and closing.
                    </p>                                                    
                    <span className="image main"><img src={pic4} alt="" /></span>                    
                </div>
            </section> 

            <GoToTop></GoToTop>
            
        </div> 

    </Layout>
)

export default Prosthetics